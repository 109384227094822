import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue")
  },
  {
    path: "/",
    name: "Index",
    redirect: "/huodong",
    meta: { titleName: "企乐缘" },
    component: () =>
      import(/* webpackChunkName: "Index" */ "../views/Index.vue"),
    children: [
      {
        path: "huodong",
        name: "Huodong",
        meta: { titleName: "社交活动" },
        component: () => import("@/views/tabBar/huodong.vue")
      },
      {
        path: "tuodan",
        name: "Tuodan",
        meta: { titleName: "缘分墙" },
        component: () => import("@/views/tabBar/tuodan.vue")
      },
      {
        path: "wode",
        name: "Wode",
        meta: { titleName: "个人中心" },
        component: () => import("@/views/tabBar/wode.vue")
      }
    ]
  },

  {
    path: "/personalData",
    name: "PersonalData",
    component: () => import("@/views/personalData.vue")
  },
  {
    path: "/matchmaker",
    name: "matchmaker",

    component: () => import("@/views/matchmaker.vue")
  },
  {
    path: "/myActivity",
    name: "myActivity",
    meta: { titleName: "我的活动" },
    component: () => import("@/views/myActivity.vue")
  },
  {
    path: "/confirmPay",
    name: "confirmPay",
    meta: { titleName: "立即报名" },
    component: () => import("@/views/confirmPay.vue")
  },
  {
    path: "/activityDetail",
    name: "activityDetail",
    meta: { titleName: "活动详情" },
    component: () => import("@/views/activityDetail.vue")
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: () => import("@/views/paySuccess.vue")
  },
  {
    path: "/check",
    name: "check",
    meta: { titleName: "券码详情" },
    component: () => import("@/views/check.vue")
  },
  {
    path: "/xieyi",
    name: "xieyi",
    meta: { titleName: "会员服务协议" },
    component: () => import("@/views/xieyi.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
