import Vue from "vue";
import axios from "axios";
import router from "./../router/index";
// import { Toast } from "vant";
// import router from "./../router";
// 环境的切换
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// 请求超时时间
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// 请求拦截器

axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // config.params.store_id = 1;

    config.params = {
      ...config.params,
      access_id: sessionStorage.getItem("access_id")
    };
    return config;
  },
  error => {
    return Promise.error(error);
  }
);
axios.interceptors.response.use(
  response => {
    if (response.status) {
      return Promise.resolve(response);
    }
    if (response.data.code == 1010131) {
      sessionStorage.removeItem("access_id");
      router.push("/login");
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);
Vue.prototype.$axios = axios;
export { axios };
