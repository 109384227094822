import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "@/plugins/axios";
import "lib-flexible/flexible";
import "normalize.css/normalize.css"; //样式初始化模块
import "vant/lib/index.css";

import VueWechatTitle from "vue-wechat-title";
import VueClipboard from "vue-clipboard2";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(VueClipboard);
Vue.use(VueWechatTitle);
Vue.use(Vant);
Vue.config.productionTip = false;

/* router.beforeEach((to, from, next) => {
  console.log(to, from);
  if (to.path !== "/huodong" && !sessionStorage.access_id) {
    console.log("66666");
    next({ path: "/huodong" });
  } else next();
  // 如果用户未能验证身份，则 `next` 会被调用两次
}); */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title;
  }
  if (to.path !== "/login" && !sessionStorage.access_id) {
    console.log("66666");
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else next();
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
